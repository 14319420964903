<template>
<div>
  <spinner v-if="loading"></spinner>
  <Reviews v-else :vendor=vendor></Reviews>
</div>
</template>

<script>
import Reviews from '../../components/Reviews.vue'

export default {
    //middleware: 'auth',
    components: {Reviews},
    data(){
        return {
          vendor: [],
          loading: false,
        };
  },
  mounted () {
    // fetch the data when the view is created and the data is
    // already being observed
    this.getItem();
  },
    methods:{
              getItem()
              {
              
                  this.loading = true;
                  let uri = '/api/vendors/' + this.$route.params.id;
                  this.$http.get(uri).then((response) => {
                      this.vendor = response.data.data,
                      this.loading = false
                  });
              },
    }
}
</script>
